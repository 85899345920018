body {
  background-color: white;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #222;
  height: 70px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.secretData {
  white-space: pre-line;
}